<template>
  <div
    ref="sourceTableContainer"
    class="report-sources__table"
  >
    <o-table
      :class="{ 'report-sources__table-details-visible': detailsVisible }"
      :current-page.sync="currentPage"
      :data="data"
      default-sort="amount"
      default-sort-direction="desc"
      detailed
      :has-detailed-visible="hasDetailedVisible"
      hoverable
      :mobile-cards="false"
      :opened-detailed="openedDetailed"
      :paginated="data.length > perPage"
      :per-page="perPage"
      :row-class="rowClass"
      scrollable
      @click="onRowClick"
      @update:openedDetailed="onOpenedDetailed"
    >
      <o-table-column
        field="funds.score"
        label="Risk Label"
        sortable
        width="500"
      >
        <template #header="{ column }">
          <div class="flex align-center gap-1">
            <div>{{ column.label }} </div>
          </div>
        </template>
        <template #default="props">
          <div class="flex column gap-1 min-w-300 max-w-400">
            <div>
              <GlBadgeInfo
                v-if="props.row.funds && props.row.funds.name"
                :text="props.row.funds.name"
                :value="props.row.funds.score ?? -1"
              />
              <GlBadgeInfo
                v-else-if="props.row.funds && props.row.funds.type"
                :text="props.row.funds.type"
                :value="props.row.funds.score ?? -1"
              />
              <GlBadgeInfo
                v-else-if="props.row.typeData"
                :text="props.row.typeData.name"
                :value="props.row.funds.score ?? -1"
              />
              <div v-else>
                --
              </div>
            </div>
            <div class="fs-12 bold grey-text-6-e">
              <div
                v-if="props.row.emptyOwner"
                class="link"
                @click="openInNewTabAddress(props.row.address)"
              >
                {{ trancateString(props.row.address) }}
              </div>
              <div
                v-else
                :class="{ 'link-text max-content': props.row.entityId }"
                @click="goToEntity(props.row.entityId)"
              >
                {{ capitalizeFirstLetter(props.row.owner) }}
              </div>
            </div>
          </div>
        </template>
      </o-table-column>

      <o-table-column
        field="amount"
        label="Amount"
        position="right"
        sortable
        width="100px"
      >
        <template #header="{ column }">
          <div class="flex align-center flex-end gap-1">
            <div>{{ column.label }} </div>
            <gl-icon
              :height="16"
              name="sort-up-down"
              style="min-width: 16px"
              :width="16"
            />
          </div>
        </template>
        <template #default="props">
          <div class="flex column gap-1">
            <div class="fs-14">
              <span
                v-popover:tooltip.top="
                  `${toComaSeparate(String(props.row.formattedAmount))}`
                "
              >{{ toComaSeparate(String(props.row.formattedAmount)) }}</span>
            </div>
            <div
              v-if="props.row.amountCur"
              class="price fs-12"
            >
              <span
                v-popover:tooltip.top="
                  `${priceMessage('historical', props.row.amountCurTimestamp)}`
                "
              >
                {{ formatByPrice(props.row.amountCur) }}
              </span>
            </div>
          </div>
        </template>
      </o-table-column>

      <o-table-column
        field="share"
        label="Exposure"
        position="right"
        sortable
        width="50px"
      >
        <template #header="{ column }">
          <div class="flex align-center flex-end gap-1">
            <div>{{ column.label }} </div>
            <gl-icon
              :height="16"
              name="sort-up-down"
              style="min-width: 16px"
              :width="16"
            />
          </div>
        </template>
        <template #default="props">
          <div v-popover:tooltip.top="`${props.row.share * 100}%`">
            {{ formatShare(roundShare(props.row.share)) }}
          </div>
        </template>
      </o-table-column>

      <o-table-column
        field="directTx"
        label="Root Tx"
        position="left"
        width="110px"
      >
        <template #header="{ column }">
          <div class="flex align-center gap-1 relative">
            <div>{{ column.label }} </div>
            <gl-icon
              class="pointer"
              :height="16"
              name="info-new"
              :width="16"
              @click="hoveredTooltipRoot = !hoveredTooltipRoot"
            />
            <GlPopoverHint
              v-model="hoveredTooltipRoot"
              :boundaries-element="sourceTableContainer"
              head-text="Root Tx"
            >
              <template #content-body>
                <div class="report-sources__table-popover-content-wrapper">
                  <div class="fs-12 mb-2">
                    "Root Tx" shows direct transactions of funds from wallet,
                    marking the point where funds first enter the wallet.
                  </div>
                  <div class="report-sources__table-popover-img-wrapper">
                    <img src="~@/assets/images/hints/rootTx-hint.png">
                  </div>
                </div>
              </template>
            </GlPopoverHint>
          </div>
        </template>
        <template #default="props">
          <div class="flex align-center">
            <div
              v-popover:tooltip.top="'Open in new tab'"
              class="soft-blue-text pointer"
              @click="openInNewTab(props.row.directTx)"
            >
              {{ trancateString(props.row.directTx, 4) || '--' }}
            </div>
            <gl-menu-item
              v-popover:tooltip.top="'Copy'"
              icon="copy-blue"
              :icon-height="20"
              :icon-width="20"
              @click="toClipBoard(props.row.directTx)"
            />
          </div>
        </template>
      </o-table-column>

      <o-table-column
        field="tx_hash"
        label="Leaf Tx"
        position="left"
        width="110px"
      >
        <template #header="{ column }">
          <div class="flex align-center gap-1">
            <div>{{ column.label }} </div>
            <gl-icon
              class="pointer"
              :height="16"
              name="info-new"
              :width="16"
              @click="hoveredTooltipLeaf = !hoveredTooltipLeaf"
            />
            <GlPopoverHint
              v-model="hoveredTooltipLeaf"
              :boundaries-element="sourceTableContainer"
              head-text="Leaf Tx"
            >
              <template #content-body>
                <div class="report-sources__table-popover-content-wrapper">
                  <div class="fs-12 mb-2">
                    "Leaf Tx" identifies the last transaction before funds reach
                    the wallet, helping to trace the origin of the money.
                  </div>
                  <div class="report-sources__table-popover-img-wrapper">
                    <img src="~@/assets/images/hints/leafTx-hint.png">
                  </div>
                </div>
              </template>
            </GlPopoverHint>
          </div>
        </template>
        <template #default="props">
          <div
            v-if="checkMultiple(props.row.tx_hash)"
            class="mr-2"
          >
            Multiple
          </div>
          <div
            v-else
            class="flex align-center"
          >
            <div
              v-popover:tooltip.top="'Open in new tab'"
              class="soft-blue-text pointer"
              @click="openInNewTab(props.row.tx_hash)"
            >
              {{ trancateString(props.row.tx_hash, 4) || '--' }}
            </div>
            <gl-menu-item
              v-popover:tooltip.top="'Copy'"
              class="sidebar__history-copy"
              icon="copy-blue"
              :icon-height="20"
              :icon-width="20"
              @click="toClipBoard(props.row.tx_hash)"
            />
          </div>
        </template>
      </o-table-column>

      <o-table-column
        field="depthSortValue"
        label="Depth"
        position="right"
        sortable
        width="60px"
      >
        <template #header="{ column }">
          <div class="flex align-center flex-end gap-1">
            <div>{{ column.label }} </div>
            <gl-icon
              :height="16"
              name="sort-up-down"
              style="min-width: 16px"
              :width="16"
            />
          </div>
        </template>
        <template #default="props">
          <div class="flex column gap-1">
            <div>
              {{ findMinMaxFields(props.row.depth) }}
            </div>
            <div class="fs-12 grey-text-6-e">
              {{ findMinMaxFieldsDirectionLabel(props.row.depth) }}
            </div>
          </div>
        </template>
      </o-table-column>

      <o-table-column
        field="timestamp"
        label="Date & Time"
        position="right"
        sortable
        width="60px"
      >
        <template #header="{ column }">
          <div class="flex align-center flex-end gap-1">
            <div>{{ column.label }} </div>
            <gl-icon
              :height="16"
              name="sort-up-down"
              style="min-width: 16px"
              :width="16"
            />
          </div>
        </template>
        <template #default="props">
          <div
            class="report-sources__table-date"
            v-html="getMinTimestampDate(props.row.timestamp)"
          />
        </template>
      </o-table-column>
      <template #detail="props">
        <div>
          <SwapData
            v-if="props.row.listType === 'Swap'"
            :currencies="currencies"
            :input="props.row.inputAmounts"
            :output="props.row.outputAmounts"
          />
        </div>
      </template>

      <template #empty>
        <div
          v-if="loading"
          class="flex align-center justify-center"
        >
          <gl-loader />
        </div>
        <div
          v-else
          class="empty-users-data flex column align-center"
        >
          <gl-icon
            :height="24"
            name="statistic"
            :width="24"
          />
          No data here yet
        </div>
      </template>
      <template #pagination>
        <div class="pp-pagination-table">
          <div class="pp-pagination-table__left" />
          <div class="pp-pagination-table__right">
            <o-pagination
              v-if="data.length && totalPages > 1"
              :current.sync="currentPage"
              :per-page="perPage"
              :range-after="0"
              :range-before="0"
              :total="total"
              @change="pageChange"
            >
              <template #default />
              <template #previous="props">
                <div class="pp-pagination-table__button-prev">
                  <o-pagination-button
                    class="pp-pagination-table__button-first-page"
                    :page="props.page"
                  >
                    <gl-menu-item
                      :disabled="props.page.disabled"
                      icon="double-arrow-left-2"
                      :icon-height="16"
                      :icon-width="16"
                      label=""
                      not-outline
                      @click.prevent="pageChange(1)"
                    />
                  </o-pagination-button>
                  <o-pagination-button :page="props.page">
                    <gl-menu-item
                      :disabled="props.page.disabled"
                      icon="arrow-left-2"
                      :icon-height="16"
                      :icon-width="16"
                      label=""
                      not-outline
                    />
                  </o-pagination-button>
                  <div class="pp-pagination-table__current">
                    {{ props.page.number + 1 }}
                  </div>
                  <div class="pp-pagination-table__current-total">
                    {{ `of ${totalPages}` }}
                  </div>
                </div>
              </template>
              <template #next="props">
                <div class="pp-pagination-table__button-next">
                  <o-pagination-button :page="props.page">
                    <gl-menu-item
                      :disabled="props.page.disabled"
                      icon="arrow-right-2"
                      :icon-height="16"
                      :icon-width="16"
                      label=""
                      not-outline
                    />
                  </o-pagination-button>
                  <o-pagination-button :page="props.page">
                    <gl-menu-item
                      :disabled="props.page.disabled"
                      icon="double-arrow-right-2"
                      :icon-height="16"
                      :icon-width="16"
                      label=""
                      not-outline
                      @click.prevent="pageChange(totalPages)"
                    />
                  </o-pagination-button>
                </div>
              </template>
            </o-pagination>
          </div>
        </div>
      </template>
    </o-table>
  </div>
</template>

<script>
// Components
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import GlBadgeInfo from '@/components/gl-badge-info'
import SwapData from '@/pages/report/components/table/SwapData'
import GlMenuItem from '@/components/gl-menu-item.vue'
import GlPopoverHint from '@/components/gl-popover-hint.vue'

// Utils
import { formatDate } from '@/utils/format-date'
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { capitalizeFirstLetter, trancateString } from '@/utils/text-formatter'
import { findMinMaxFields, findMinMaxFieldsDirectionLabel } from '@/utils/model'
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { checkMultiple } from '@/utils/report-data-formatter'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
import { goToEntity } from '@/utils/go-to-route'
import { toClipBoard } from '@/utils/helpers'

// Vuex
import { mapState } from 'vuex'

export default {
  components: {
    SwapData,
    GlMenuItem,
    GlLoader,
    GlIcon,
    GlBadgeInfo,
    GlPopoverHint,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currencies: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hoveredTooltipRoot: false,
      hoveredTooltipLeaf: false,
      sourceTableContainer: null,
      perPage: 8,
      currentPage: 1,
      openedDetailed: [],
      detailsVisible: true,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinType']),
    total() {
      return this.data.length
    },
    totalPages() {
      return Math.ceil(this.data.length / this.perPage)
    },
  },
  watch: {
    data: {
      handler() {
        this.pageChange(1)
      },
    },
  },
  mounted() {
    this.sourceTableContainer = this.$refs.sourceTableContainer
  },
  methods: {
    formatDate,
    toClipBoard,
    formatBtcAmount,
    capitalizeFirstLetter,
    findMinMaxFields,
    findMinMaxFieldsDirectionLabel,
    trancateString,
    roundShare,
    checkMultiple,
    formatShare,
    toComaSeparate,
    formatByPrice,
    priceMessage,
    goToEntity,
    pageChange(event) {
      this.currentPage = event
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const currentPageRows = this.data.slice(start, end)
      this.detailsVisible = currentPageRows.some(row =>
        this.hasDetailedVisible(row),
      )
    },
    getMinTimestampDate(val) {
      const timestamp = (val && val?.minimum) || val

      return timestamp
        ? this.formatDate(timestamp * 1000, 'dd.MM.yyyy HH:mm', true)
        : ''
    },
    openInNewTab(val) {
      const { href } = this.$router.resolve({
        name: 'analytics',
        query: { tx: val, type: this.coinType },
      })
      window.open(href, '_blank')
    },
    openInNewTabAddress(address) {
      const { href } = this.$router.resolve({
        name: 'analytics',
        query: { address, type: this.coinType },
      })
      window.open(href, '_blank')
    },
    hasDetailedVisible(row) {
      return row.inputAmounts && row.outputAmounts && row.listType === 'Swap'
    },
    rowClass(row) {
      return this.hasDetailedVisible(row) ? 'pointer' : ''
    },
    onRowClick(row) {
      const index = this.openedDetailed.indexOf(row)
      if (index !== -1) {
        this.openedDetailed.splice(index, 1)
      } else {
        this.openedDetailed.push(row)
      }
    },
    onOpenedDetailed(row) {
      this.openedDetailed = row
    },
  },
}
</script>
<style>
.report-sources__table {
  position: relative;
}
.report-sources__table-date {
  min-width: max-content;
  text-align: right;
}
.report-sources__table-date .pp-date__time {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--dark-grey-6-e) !important;
}

.report-sources__table .o-table .o-table__th {
  color: var(--space-cadet) !important;
  font-size: 12px;
  padding: 8px 12px !important;
  padding-top: 10px !important;
  font-weight: 600 !important;
  text-transform: none;
  border: none;
  white-space: nowrap;
}
.report-sources__table .o-table .o-table__th.o-table__th-current-sort {
  font-weight: 900 !important;
}

.report-sources__table .o-table .o-table__td {
  font-size: 14px;
  padding: 8px 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  min-width: max-content;
  border-bottom: 1px solid var(--Accent-Coton-Grey);
}

.report-sources__table .o-table__detail > td {
  padding: 0 8px 0 12px;
}

.report-sources__table tbody > tr .o-table__td.o-table__td-chevron {
  display: none;
}
.report-sources__table thead .o-table__th--detailed {
  display: none;
}

.report-sources__table
  .report-sources__table-details-visible
  tbody
  > tr
  .o-table__td.o-table__td-chevron {
  display: table-cell;
  padding: 0 4px !important;
  width: 0 !important;
}
.report-sources__table
  .report-sources__table-details-visible
  thead
  .o-table__th--detailed {
  display: table-cell;
  padding: 0 !important;
  padding-left: 8px !important;
  width: 0 !important;
}

.report-sources__table
  .report-sources__table-details-visible
  .o-table__detail
  > td {
  padding: 0 12px 0 8px;
}

.report-sources__table
  .report-sources__table-details-visible
  .o-table__th:nth-child(2),
.report-sources__table
  .report-sources__table-details-visible
  .o-table__td:nth-child(2) {
  padding: 0px !important;
}

.report-sources__table
  tbody
  > tr:has(+ .o-table__detail)
  .o-table__td-chevron
  .o-icon--clickable {
  transform: rotate(-90deg) !important;
}
.report-sources__table
  tbody
  > tr:has(+ .o-table__detail)
  .o-table__td-chevron
  .o-icon--clickable
  .mdi-chevron-right::before {
  color: var(--soft-blue);
}

.report-sources__table .o-table__td.o-table__td-chevron .o-icon--clickable {
  transform: rotate(90deg) !important;
}
.report-sources__table
  .o-table__td.o-table__td-chevron
  .o-icon--clickable
  .mdi-chevron-right::before {
  color: var(--space-cadet);
}

.report-sources__table tbody:before {
  line-height: 8px;
  content: '\200C';
  display: block;
}

.report-sources__table .o-table__th:last-child,
.report-sources__table .o-table__td:last-child {
  padding-right: 40px !important;
}

.report-sources__table .pp-pagination-table {
  padding: 16px;
  padding-top: 12px;
}

.report-sources__table-popover-content-wrapper {
  width: 470px;
  max-width: 70vw;
}

.report-sources__table-popover-img-wrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.report-sources__table .pp-pagination-table__button-first-page {
  margin-right: -4px;
}
.report-sources__table .pp-pagination-table .o-pag__link {
  border-color: var(--space-cade) !important;
}
</style>
